import React from 'react'

import '../styles/newsletter.css'

export default class NewsletterRibbon extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            FNAME: '',
            LNAME: '',
            EMAIL: ''
        }
    }

    handleChange = ({ target: { value, name }}) => 
    this.setState({
        [name]: value
    })


    render() {
        return (
            <div className="newsletter-section">
                <div id="mc_embed_signup">
                <div className="newsletter-wrapper">
                    <h2>Subscribe</h2>
                    <p>Stay updated with our newsletter</p>
    
                    <form action="https://foundstages.us9.list-manage.com/subscribe/post?u=0a8108e3235f20a8785433e42&amp;id=296f5d5312" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
    
                        <div class="mc-field-group email-field">
                            <input type="email" value={this.state.EMAIL} name="EMAIL" placeholder="Email *" class="required email" id="mce-EMAIL" onChange={this.handleChange} />
                        </div>
                        <div class="name-container">
                            <div class="mc-field-group" id="half-width">
                                <input type="text" value={this.state.FNAME}  name="FNAME" placeholder="First Name" class="" id="mce-FNAME" onChange={this.handleChange}/>
                            </div>
                            <div class="mc-field-group" id="half-width">
                                <input type="text" value={this.state.LNAME} name="LNAME" placeholder="Last Name" class="" id="mce-LNAME" onChange={this.handleChange}/>
                            </div>
                        </div>
                            <div id="mce-responses" class="clear">
                                <div class="response" id="mce-error-response" style={{display:'none'}}></div>
                                <div class="response" id="mce-success-response" style={{display:'none'}}></div>
                            </div>    
                            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_0a8108e3235f20a8785433e42_296f5d5312" tabindex="-1" value="" /></div>
                            <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" /></div>
                            </div>
                    </form>
                </div>
                </div>
            </div>
        )
    }
    
}