import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewsletterRibbon from '../components/NewsletterRibbon';

import Layout from "../components/layout"
import SEO from "../components/seo"

import ansley from '../images/wr-sponsors/ansley-wine-logo-2.png'
import IBN from '../images/wr-sponsors/IBN-logo-optimized.png'
import SP from '../images/wr-sponsors/sp_900x330.png'
import Handy from '../images/wr-sponsors/Mr-Handyman-Logo-optimized-2.png'
import Statefarm from '../images/wr-sponsors/state-farm.jpg'
import MtVernon from '../images/wr-sponsors/mount-vernon.png'
import Steifel from '../images/wr-sponsors/stiefel-name.jpeg'

import BackgroundImage from 'gatsby-background-image'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  productionsWrapper: {
    paddingBottom: "1em",
    maxWidth: "75rem",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column"
  },
  showsWrapper: {
    paddingBottom: "1em",
    maxWidth: "60rem",
    margin: "20px auto",
    display: "flex",
    flexDirection: "column"
  },
  button: {
    margin: theme.spacing(1),
  },
  jumbo: {
    height: "100vh",
    backgroundSize: "cover"
  },
}));


export const query = graphql`
query {
  jumbo: file(relativePath: { eq: "wr-cropped.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  },
}
`

export default function CenteredGrid( { data } ) {
  const classes = useStyles();

  return (
    <Layout>
    <SEO title="Wine and Reading Series" />
    {/* <div class="jumbotron-wrapper-wine"> */}
    <BackgroundImage 
        Tag="section"
        className={classes.jumbo}
        fluid={data.jumbo.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        >
      <div class="jumbo-inner-wrapper-wine">
          <div>
            <h1 class="jumbo-title-wine">
                Wine & Reading Series
            </h1>
            {/* <h5 class="subtitle-wine">At Dunwoody Nature Center</h5>
            <div class="wine-buttons">
                <a title="Buy Tickets to the Wine and Reading Series" href="https://wine-and-reading-series.eventbrite.com/" class="button button--bigger blackButton--border">
                    Buy Tickets
                    </a>
                <a title="Sponsor Found Stages!" href="https://www.nationaltheatrescotland.com/production/them/" class="button button--bigger blackButton--border">
                    Become a Sponsor
                    </a>
          </div> */}
          </div>
      </div>
    </BackgroundImage>
    {/* </div> */}
    <div className={classes.root}>
      {/* <Grid container spacing={3} className={classes.productionsWrapper}> */}

    <div class="about-wrapper">
    <p class="about">On the second Sunday of each month, May through October, a new original play will be unveiled amid the relaxing backdrop of beautiful Dunwoody Nature Center. Each play was written by a nationally known writer who calls Atlanta home.
    Enjoy complimentary wine and appetizers as Atlanta’s talented professional actors bring the play’s characters to life. You’ll be given your own creative license to imagine the look, feel and sounds that make up the setting of the story.</p>
    <p>Each event will start and end with a reception, where you’ll have the opportunity to meet the play’s writer, director and actors. Be sure to leave time in your visit to explore the grounds of the Nature Center! </p><br></br>

    <br></br>
    <strong>Second Sundays at 2:00 PM ~ Original Plays by Atlanta Writers</strong> <br></br>
    <br></br>

    MAY 12 ~ Lee Osorio <br></br>

    JUN 9 ~ Edith Freni <br></br>

    JUL 14 ~ Mark Kendall <br></br>
    
    AUG 11 ~ Lee Nowell <br></br>

    SEP 8 ~ Margaret Baldwin <br></br>

    OCT 13 ~ Danielle Deadwyler <br></br>

    (All events run from 2pm-4pm inside the North Woods Pavilion at Dunwoody Nature Center.)
    </div>

      <div class="about-title-wrapper">
              <div class="productions-title">
                  Coming Up
              </div>
          </div>
            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block upcoming-pic-1">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">And Cauldron Bubble</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Lee Nowell</p>
                        <p><strong>Date:</strong> August 11th, 2019</p>
                    </div>

                    <p>King James is in a quandary.  He sits on the throne of England, but he doesn’t technically have the right to rule.  Coupled with that is his constant feeling that witches are out to get him.  In order to keep the throne, he needs to invent a lie that justifies his right to rule.   King James commissions Shakespeare to write a propaganda play that will rewrite his own ancestry.  Meanwhile, witches abound in Scotland, England, Ireland, and the newly formed American colonies, and King James can’t kill them quickly enough to feel safe.  Based entirely on historical fact, this is the reason Shakespeare wrote Macbeth.</p>
                </div>
            </div>
            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block upcoming-pic-2">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">Sunday Morning 2 Men Cooking</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Margaret Baldwin</p>
                        <p><strong>Date:</strong> September 8th, 2019</p>
                    </div>

                    <p>(A play about God and cooking.) Two men, Little Buck and Big Silver, in a bare and broken-down kitchen in the South make a feast to honor the memory of their beloved matriarch, Granny Lippy, whose motto: If you can read, you can cook inspires their feast. Their preparations veer from ecstatic praise to dangerous ground when Little Buck starts to question the ritual. His challenge invokes Big Silver’s wrath and threatens to destroy their world order, until a surprise visitor brings hope for mercy.</p>
                </div>
            </div>
            <div class="pastwork-content-row pastwork-row">
                <div class="pastwork-pic-block upcoming-pic-3">
                </div>
                <div class="pastwork-content-block text-block">
                    <h2 class="glowist forty-font">Rip.</h2>
                    <div class="byline">
                        <p><strong>Written By:</strong> Danielle Deadwyler</p>
                        <p><strong>Date:</strong> October 13th, 2019</p>
                    </div>

                    <p>A young couple unravels the challenges of their premature marriage, in a poetic excavation of time, family, and loyalty. Equal parts performance art and domestic drama…Where do the origins of collapsing love lie? And what emerges after a Rip?</p>
                </div>
            </div>
      {/* </Grid> */}

      <div class="about-title-wrapper">
              <div class="productions-title centered">
                  Sponsors
              </div>
          </div>

        <div class="sponsors-section">
                <div class="sponsor-box">
                    <h3>Gold Sponsor</h3>
                    <div class="sponsor-logo-wrapper">
                        <img alt="" src={IBN} style={{height: 100}} />
                        <img alt="" src={SP} style={{height: 100}}/>
                    </div>
                    
                </div>
                <div class="sponsor-box">
                    <h3>Silver Sponsor</h3>
                    <div class="sponsor-logo-wrapper">
                        <img alt="" src={Handy} style={{height: 100}} />
                    </div>
                </div>

                <div class="sponsor-box">
                    <h3>Bronze Sponsor</h3>
                    <div class="sponsor-logo-wrapper">
                        <img alt="" src={Statefarm} style={{height: 100}} />
                        <img alt="" src={MtVernon} style={{height: 100}} />
                    </div>
                    
                </div>
                <div class="sponsor-box">
                    <h3>Wine Sponsor </h3>
                    <div class="sponsor-logo-wrapper">
                        <img alt="" src={ansley} style={{height: 100}} />
                    </div>
                    
                </div>
                <div class="sponsor-box">
                    <h3>Series Supporters </h3>
                    <div class="sponsor-logo-wrapper">
                        <img alt="" src={Steifel} style={{height: 100}} />
                    </div>
                </div>
            </div>
    {/* Subscribe newsletter ribbon */}
      <NewsletterRibbon />
      {/* <Grid container spacing={3} className={classes.showsWrapper}> */}

      <div class="news-title-wrapper">
          <div class="productions-title">
              2019 Series
          </div>
        </div>

      <div class="content-box-row">
        <div class="wrapper">
                <div class="top wr-card-9">
                  </div>
                  <div class="bottom">
                      <h4 class="card-title">Diversity Now!</h4>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                      <p class="production-card__locations">By Mark Kendall</p>

                </div>
            </div>
          <div class="wrapper">
              <div class="top wr-card-7">
                  </div>
                  <div class="bottom">
                      <h4 class="card-title">Faith</h4>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                      <p class="production-card__locations">By Lee Osorio</p>

                </div>
            </div>
            <div class="wrapper">
                <div class="top wr-card-8">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">The Hystericals</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Edith Freni</p>
                </div>
            </div>
        </div>

        <div class="buffer"></div>

        <div class="news-title-wrapper">
          <div class="productions-title">
              2018 Series
          </div>
        </div>

        <div class="content-box-row">
          <div class="wrapper">
              <div class="top wr-card-1">
                  </div>
                  <div class="bottom">
                      <h4 class="card-title">She Calls Up the Sun</h4>
                      {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                      <p class="production-card__locations">By Addae Moon</p>

                </div>
            </div>
            <div class="wrapper">
                <div class="top wr-card-2">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">General Gabler’s Daughter</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Annie Harrison Elliott</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top wr-card-3">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">The Year Without Summer</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Neeley Gossett</p>
                </div>
            </div>
        </div>
        <div class="content-box-row">
          <div class="wrapper">
              <div class="top wr-card-4">
                  </div>
                  <div class="bottom">
                        <h4 class="card-title">He Sees the Field / He Wants to Run</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Jiréh Breon Holder</p>

                </div>
            </div>
            <div class="wrapper">
                <div class="top wr-card-5">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Heart Chakra Block</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Gabrielle Fulton</p>
                </div>
            </div>
            <div class="wrapper">
                <div class="top wr-card-6">
                    </div>
                    <div class="bottom">
                        <h4 class="card-title">Kamikaze Drug Lords of the Sunbelt</h4>
                        {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                        <p class="production-card__locations">By Phillip DePoy</p>
                </div>
            </div>
        </div>           

      {/* </Grid> */}
    </div>
    </Layout>
  );
}
